/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// var $ = require('jquery');

import $ from 'jquery';

global.$ = global.jQuery = $;

// Libraries
import 'bootstrap';
import 'owl.carousel';
import 'lazysizes';

import 'lightgallery';
import 'lg-thumbnail';
import 'lg-fullscreen';
import 'lg-video';
import 'lg-autoplay';
import 'lg-zoom';

import 'jquery-match-height-browserify';

import common from './common/common';

$(document).ready(function () {

    common();
    let carousel = $('.owl-carousel--nextSale');

    $('.owl-carousel--nextSale').on({
        'initialized.owl.carousel': function () {
            carousel.find('.item-sale').show();
        }
    }).owlCarousel({
        margin: 10,
        dost: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
                margin: 0,
            },
            576: {
                items: 2,
                margin: 30,
            },
            768: {
                items: 2,
                margin: 30,
            },
            992: {
                items: 3,
                margin: 30,
            }
        }
    });

    $('.owl-carousel--saleHasProduct').on({
        'initialized.owl.carousel': function () {
            carousel.find('.item-card').show();
        }
    }).owlCarousel({
        margin: 10,
        dost: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
                margin: 0,
            },
            576: {
                items: 2,
                margin: 30,
            },
            768: {
                items: 3,
                margin: 30,
            },
            992: {
                items: 4,
                margin: 30,
            }
        }
    });

    // Carousel "view lot"
    $('.owl-carousel--view_saleHasProduct').on({
        'initialized.owl.carousel': function () {
            carousel.find('.item-card').show();
        }
    }).owlCarousel({
        margin: 10,
        dost: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
                margin: 5,
            }
        }
    });

    //matchHeight "view our-team"
    var $items = $('.item');
    if ($items.length > 1) {
        $items.matchHeight();
    }

});
