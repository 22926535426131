import * as FormValidation from 'formvalidation';
import Bootstrap from 'formvalidation/dist/es6/plugins/Bootstrap';

$(document).ready(function () {
    const form = document.getElementById('newsletter-form');
    if (form !== null) {
        FormValidation.formValidation(form, {
            fields: {
                'sale_alert_registration[email]': {
                    validators: {
                        notEmpty: {
                            message: "L'adresse email est obligatoire."
                        },
                        emailAddress: {
                            message: "N'est pas une adresse mail valide."
                        }
                    }
                }
            },
            plugins: {
                trigger: new FormValidation.plugins.Trigger(),
                bootstrap: new Bootstrap(),
                submitButton: new FormValidation.plugins.SubmitButton(),
                defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
                /*icon: new FormValidation.plugins.Icon({
                    valid: 'fa fa-check',
                    invalid: 'fa fa-times',
                    validating: 'fa fa-refresh'
                }),*/ //Avec Bootstrap 4, ne pas oublier d'importer une fontIcons
                // declarative
                //declarative: new FormValidation.plugins.Declarative()
            }
        });
    }
});
