import * as FormValidation from 'formvalidation';
import Bootstrap from 'formvalidation/dist/es6/plugins/Bootstrap';

$(document).ready(function () {
    const form = document.getElementById('contact-lot-form');
    if (form !== null) {
        FormValidation.formValidation(form, {
            fields: {
                'contact[lead][gender]': {
                    validators: {
                        notEmpty: {
                            message: "La civilité est obligatoire."
                        }
                    }
                },
                'contact[lead][lastname]': {
                    validators: {
                        notEmpty: {
                            message: "Le nom est obligatoire."
                        },
                        stringLength: {
                            max: 80,
                            message: 'Maximum de 80 caractères.'
                        },
                    }
                },
                'contact[lead][phone]': {
                    validators: {
                        notEmpty: {
                            message: "Le téléphone est obligatoire."
                        },
                        stringLength: {
                            max: 30,
                            message: 'Maximum de 30 caractères.'
                        },
                    }
                },
                'contact[lead][email]': {
                    validators: {
                        notEmpty: {
                            message: "L'adresse email est obligatoire."
                        },
                        emailAddress: {
                            message: "N'est pas une adresse mail valide."
                        }
                    }
                },
                'contact[message]': {
                    validators: {
                        notEmpty: {
                            message: "Cette valeur ne doit pas être nulle."
                        },
                        stringLength: {
                            max: 3000,
                            message: 'Le message doit faire moins de 3000 caractères.'
                        },
                    }
                },
                'contact[gdpr][gdpr]': {
                    validators: {
                        notEmpty: {
                            message: "Vous devez acceptez la politique de confidentialité."
                        },
                    }
                },
                'contact[identityCard]': {
                    validators: {
                        notEmpty: {
                            message: "Carte d'identité obligatoire."
                        },
                        file: {
                            extension: 'jpeg,jpg,png,pdf',
                            type: 'image/jpeg,image/png,application/pdf',
                            maxSize: 2097152,   // 2048 * 1024
                            message: 'The selected file is not valid'
                        }
                    }
                },
                'contact[rib]': {
                    validators: {
                        notEmpty: {
                            message: "RIB obligatoire."
                        },
                    }
                }
            },
            plugins: {
                trigger: new FormValidation.plugins.Trigger(),
                bootstrap: new Bootstrap(),
                submitButton: new FormValidation.plugins.SubmitButton(),
                defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
                /*icon: new FormValidation.plugins.Icon({
                    valid: 'fa fa-check',
                    invalid: 'fa fa-times',
                    validating: 'fa fa-refresh'
                }),*/ //Avec Bootstrap 4, ne pas oublier d'importer une fontIcons
                // declarative
                //declarative: new FormValidation.plugins.Declarative()
            }
        });
    }
});