import * as FormValidation from 'formvalidation';
import Bootstrap from 'formvalidation/dist/es6/plugins/Bootstrap';

$(document).ready(function () {

    const form = document.getElementById('contact-expertise');

    if (form !== null) {
        var fv = FormValidation.formValidation(form, {
            fields: {
                'expertise[lead][gender]': {
                    validators: {
                        notEmpty: {
                            message: "La civilité est obligatoire."
                        }
                    }
                },
                'expertise[lead][lastname]': {
                    validators: {
                        notEmpty: {
                            message: "Le nom est obligatoire."
                        },
                        stringLength: {
                            max: 80,
                            message: 'Maximum de 80 caractères.'
                        },
                    }
                },
                'expertise[lead][phone]': {
                    validators: {
                        notEmpty: {
                            message: "Le téléphone est obligatoire."
                        },
                        stringLength: {
                            max: 30,
                            message: 'Maximum de 30 caractères.'
                        },
                    }
                },
                'expertise[lead][email]': {
                    validators: {
                        notEmpty: {
                            message: "L'adresse email est obligatoire."
                        },
                        emailAddress: {
                            message: "N'est pas une adresse mail valide."
                        }
                    }
                },
                'expertise[message]': {
                    validators: {
                        notEmpty: {
                            message: "Cette valeur ne doit pas être nulle."
                        },
                        stringLength: {
                            max: 3000,
                            message: 'Le message doit faire moins de 3000 caractères.'
                        },
                    }
                },
                'expertise[gdpr][gdpr]': {
                    validators: {
                        notEmpty: {
                            message: "Vous devez acceptez la politique de confidentialité."
                        },
                    }
                }
            },
            plugins: {
                trigger:       new FormValidation.plugins.Trigger(),
                bootstrap:     new Bootstrap(),
                submitButton:  new FormValidation.plugins.SubmitButton(),
                defaultSubmit: new FormValidation.plugins.DefaultSubmit(),
            }
        });
    }

    $('.add-photo').click(function (e) {
        var list = $($(this).attr('data-list'));

        // Try to find the counter of the list
        var counter = list.data('widget-counter') | list.children().length;
        // If the counter does not exist, use the length of the list
        if (!counter) { counter = list.children().length; }

        if (counter <= expertiseNbrPhotos - 1) {
            // grab the prototype template
            var newWidget = list.attr('data-prototype');
            // replace the "__name__" used in the id and name of the prototype
            // with a number that's unique to your emails
            // end name attribute looks like name="contact[emails][2]"
            newWidget = newWidget.replace(/__name__/g, counter);
            // Increase the counter
            counter++;
            // And store it, the length cannot be used if deleting widgets is allowed
            list.data(' widget-counter', counter);
            // create a new list element and add it to the list
            var newElem = $(list.attr('data-widget-tags')).html(newWidget);
            newElem.appendTo(list);

            let attrName = newElem.children('div').children('div').children('div').children('input[type=file]').attr('name');

            fv
                .addField(attrName,{
                    validators: {
                        notEmpty: {
                            message: "Photo obligatoire.",
                            enabled: true,
                        },
                        file: {
                            extension: 'jpeg,jpg,png',
                            type: 'image/jpeg,image/png',
                            maxSize: 58720256,
                            message: 'Fichier non valide',
                            enabled: true,
                        }
                    }
                });
        }
    });

    $('#email-fields-list').on('click', '.form-check-label' ,function () {
        let index      = $(this).attr('for').match(/\d+/g).map(Number)[0];
        const attrName = `expertise[photos][${index}][binaryContent]`;
        this.closest('li').remove();
        fv
            .removeField(attrName);
    });
});