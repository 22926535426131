function initItemClick() {
    $('.item-card').on('click', function (e) {
        var link = $(this).find('h3 a').attr('href');
        window.location = link;
    });
}

function initAccordionClick() {
    $('.accordion button').on('click', function (e) {
        $('.accordion button span i').removeClass("rotate-icon");
        let iconBtn = $(this).find('span i');
        iconBtn.addClass("rotate-icon");
    });
}


/*
function initFormValidation() {
    // @see https://formvalidation.io/guide/api/
    $('form').not('.no-fv').formValidation({
        framework: 'bootstrap',
        icon: {
            valid: 'icon-success',
            invalid: 'icon-error',
            validating: 'icon-success'
        },
        err: {
            container: 'tooltip'
        },
        locale: 'fr_FR'
    })
        .on('err.field.fv', function (e, data) {
            // console.log(e);
            // Get the tooltip
            var $icon = data.element.data('fv.icon'),
                title = $icon.data('bs.tooltip').getTitle();

            // Destroy the old tooltip and create a new one positioned to the right
            // $icon.tooltip('destroy').tooltip({
            //     html: true,
            //     placement: 'bottom',
            //     title: title
            // });
        });

    $('form').formValidation({
        framework: 'bootstrap',
        icon: {
            valid: 'icon-success',
            invalid: 'icon-error',
            validating: 'icon-success'
        },
        err: {
            container: 'tooltip'
        },
        excluded: ':disabled',
        locale: 'fr_FR',
        fields: {
            birthdate: {
                validators: {
                    notEmpty: {
                        message: 'The date of birth is required'
                    },
                    date: {
                        format: 'dd/mm/yyyy',
                        message: 'The date of birth is not a valid date'
                    }
                }
            }
        }
    });
}
*/

export default function init() {
    initItemClick();
    initAccordionClick();
}